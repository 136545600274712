.justify-content-center {
  display: flex;
  justify-content: center; 
}

.category-link {
  color: inherit !important;
} 

.nav-cat{
	font-weight: 200;
	font-size: 12px;
	background-color: #292929;
	color:#fff;
}

.top-hero-padding{
	padding-top: 0 !important;
}