.btn-btn-black {
  background-color: #3b3b3b !important;
  border: 1px solid #000000 !important;
  display: inline-block;
  color: #ffffff !important;
  font-size: 13px;
  padding: 10px 19px;
  width: 120px;
  background-image: none !important;
}

.no-friends-padding {
  padding-top: 80px;
}